import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FullLayout from '../layouts/FullLayout';

function ProtectRouteAgent() {
  // const { token: Usertoken } = useSelector((state) => state.user);
  const { user: currentUser } = useSelector((state) => state.user);

  // return Usertoken && currentUser.RoleName  === 'Agent' ? <FullLayout /> : <Navigate to="/Login" />;
  return currentUser.role === '6' ? <FullLayout /> : <Navigate to="/Login" />;
}
export default ProtectRouteAgent;
