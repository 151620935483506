import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';
import _ from 'lodash';

const initialState = {
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};

export const adminLedgerReport = createEncryptedAsyncThunk(
  'adminReportSlice/adminLedgerReport',
  'Wallet/GetLedgerDetail',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.selectRetailer.value,
      service_Id: 0,
      transaction_type: '',
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  },
);

export const adminLedgerReportByTxnID = createEncryptedAsyncThunk(
  'adminReportSlice/adminLedgerReportByTxnID',
  'Wallet/GetLedgerDetail',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.selectRetailer.value,
      service_Id: 0,
      transaction_type: '',
      transaction_ID: params.transactionId || '',
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  },
);

export const GetProfitReportDayWise = createEncryptedAsyncThunk(
  'adminReportSlice/GetProfitReportDayWise',
  'account/GetProfitReportDayWise',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      reportUserId: _.toNumber(params.selectRetailer.value) || 0,
      fromDate: params.fromDate,
      toDate: params.toDate,
    }),
  },
);

export const checkBankStatus = createEncryptedAsyncThunk(
  "adminReportSlice/checkBankStatus",
  "account/DMT_TransactionStatusCheckAPIDirect",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      transactionId: params.data.txn_id,
      updateTransactionFlag: params.updateTransactionFlag || false,
    }),
  }
);

export const adminBillPaymentReport = createEncryptedAsyncThunk(
  'adminReportSlice/adminBillPaymentReport',
  'account/GetBBPSReport',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bbps_userid: params.selectRetailer.value,
      txn_id: '',
      txn_type: '',
      txn_mode: '',
      customerNo: '',
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  },
);
export const getRetailerList = createEncryptedAsyncThunk(
  'adminReportSlice/adminBillPaymentReport',
  (params) =>
    `/Reports/UserLedgerReport?MobileNo=${params.selectRetailer.mobile}&FromDate=${params.fromDate}&ToDate=${params.toDate}&Role=${params.Role}`,
  {
    method: 'GET',
  },
);
export const getCommissionReport = createEncryptedAsyncThunk(
  "adminReportSlice/getCommissionReport",
  "Service/GetCommissionReport",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  }
);
export const getAdminMoneyTransactionHistoryHold = createEncryptedAsyncThunk(
  'adminReportSlice/getAdminMoneyTransactionHistoryHold',
  'account/GetDMTTransaction',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      txn_id: '',
      dmt_transaction_userid: params.selectDistributor.value || 0,
      startDate: params.fromDate ? params.fromDate : '',
      endDate: params.toDate ? params.toDate : '',
    }),
  },
);
export const AdminAEPSTransactionReports = createEncryptedAsyncThunk(
  'reportSlice/AdminAEPSTransactionReports',
  'account/GetAEPSReport',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      report_userid: params.selectRetailer ? params.selectRetailer.value : params.selectRetailer.value,
      txn_id: '',
      apiId: 0,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  },
);
export const confirmSuccessManually = createEncryptedAsyncThunk(
  'adminReportSlice/confirmSuccessManually',
  'Transaction/UpdateTransStatus',
  {
    prepareBody: (params) => ({
      walletId: params.dmtId,
      status: 1,
      userID: 85,
    }),
  },
);

export const ReverseTrasactionAction = createEncryptedAsyncThunk(
  'adminReportSlice/ReverseTrasaction',
  'account/ReverseTrasaction',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      transactionId: params.body.transaction_Id,
      userID: params.currentUser.userGuid,
      service_Type: params.service_Type || "",
      service_Id: params.service_Id || 0,
      walletType: "1",
    }),
  },
);
export const GetGstTransaction = createEncryptedAsyncThunk(
  'reportSlice/GetGstTransaction',
  'Wallet/GetLedgerTDSDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.selectRetailer.value,
      service_Id: 0,
      transaction_type: '',
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  },
);
export const confirmFailedManually = createEncryptedAsyncThunk(
  'adminReportSlice/confirmFailedManually',
  'Transaction/UpdateTransStatus',
  {
    prepareBody: (params) => ({
      walletId: params.dmtId,
      status: 3,
      userID: 85,
    }),
  },
);
export const kycPayementReports = createEncryptedAsyncThunk(
  'adminReportSlice/confirmFailedManually',
  'account/CFPGGetAllPG',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      pG_transaction_userid: params.selectRetailer.value,
      transactionid: '',
      startDate: params.fromDate,
      endDate: params.toDate,
      status: [0, 1, 2],
    }),
  },
);

export const adminGetAPIRequestResponseLog = createEncryptedAsyncThunk(
  'adminReportSlice/adminGetAPIRequestResponseLog',
  'Service/GetAPIRequestResponseLog',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      requestType: '',
      reference_ID: params.referenceId || '',
      // reference_ID: '',
      service_Name: params.serviceId || 0,
      startDate: params.fromDate,
      endDate: params.toDate,

    }),
  },
);

export const adminReportSlice = createSlice({
  name: 'adminReportSlice',
  initialState,
  reducers: {
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const { FetchSuccess, FetchFailed, DeleteSuccess, DeleteFailed, DeleteInitial } =
  adminReportSlice.actions;
export default adminReportSlice.reducer;
