import { createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../Utility/APIUtils";

const initialState = {
  // creditAmount
  creditAmountSucess: false,

  creditAmountResponse: {
    error: false,
    message: "",
  },
  commonDeleteResponse: {
    status: false,
    message: "",
  },
  commonDeleteSucces: false,
  error: "",
  loading: false,
};

export const createDistributorFromSuperDistributer = createEncryptedAsyncThunk(
  "manageRoleDistributor/createDistributorFromSuperDistributer",
  "account/CreateUser",
  {
    // transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => {
      const todayDate = new Date().toISOString();
      return {
        userID: params.currentUser.userGuid,
        nameTitle: params.updatedRetailer.nameTitle,
        username: "",
        password: "",
        emailAddress: params.updatedRetailer.emailID,
        firstName: params.updatedRetailer.fName,
        middleName: params.updatedRetailer.mName,
        lastName: params.updatedRetailer.lName,
        primaryPhone: params.updatedRetailer.mobileNo,
        primaryPhoneType: 0,
        secondaryPhone: params.updatedRetailer.altMobileNo,
        secondaryPhoneType: 0,
        role: params.roleId,
        senior_ID: params.currentUser.userId,
        business_Name: params.updatedRetailer.business_Name,
        business_Add: params.updatedRetailer.business_Add,
        business_Contact_No: params.updatedRetailer.business_Contact_No,
        business_Type: "string",
        state_ID: params.selectState.value,
        city_ID: params.selectCity.value,
        pincode: "string",
        area: "string",
        landMark: "string",
        address: params.updatedRetailer.address,
        locationtype: params.updatedRetailer.locationtype || 0,
        created_By: params.currentUser.userId,
        plan_id: 0,
        gender: params.gender || 0,
        dob: params.dob || todayDate,
        custom_Attributes: {
          ip: params.ipAddress ? params.ipAddress : "",
          client: "string",
          version: "string",
          deviceId: "string",
          deviceType: "",
          os: "string",
          lattitude: "string",
          longitude: "string",
        },
      };
    },
  }
);
// export const getAllDistributor = createEncryptedAsyncThunk(
//   'manageRoleDistributor/getAllDistributor',
//   (params) =>
//     `account/GetUserDetailsByRole?userID=${params.currentUser.userId}&role=${params.roleId}`,
//   {
//     method: 'GET',
//   },
// );
export const getAllDistributor = createEncryptedAsyncThunk(
  "UserManagement/getRolewiseUser",
  "account/GetUserDetailsByRole",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      role: params.roleId,
      documentRequired: 0,
      status: 3,
    }),
  }
);
export const manageRoleSPDistributor = createSlice({
  name: "manageRoleSPDistributor",
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return {
        ...state,
        creditAmountResponse: { error: true, message: action.payload },
      };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return {
        ...state,
        rechargeResponse: { status: true, message: action.payload },
      };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: "" },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = manageRoleSPDistributor.actions;
export default manageRoleSPDistributor.reducer;
