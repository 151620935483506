import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';

const initialState = {
  benDeleteSuccess: false,
  error: '',
  loading: false,
  creditAmountSucess: false,
  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
};

export const addNewBeneficiary = createEncryptedAsyncThunk(
  'beneficiary/addNewBeneficiary',
  'transaction/RegisterBeneficiary',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      mobile: params.Mobile.customerMobileNumber,
      benename: params.beneBificiaryName,
      bankid: `${params.selectBank.value}`,
      accno: params.accountNumber,
      ifsccode: params.IfscCode,
      verified: '0',
      gst_state: `${params.loginData.state_ID}`,
      dob: '1992-04-05',
      address: 'AT-123',
      pincode: '400706',
    }),
  },
);
export const addNewBeneficiarySelf = createEncryptedAsyncThunk(
  'beneficiary/addNewBeneficiarySelf',
  'account/CreateBenificiary',
  {
    prepareBody: (params) => ({
      remt_mobileno: params.Mobile.customerMobileNumber,
      cust_id: params.custId,
      userID: params.currentUser.userGuid,
      account_holder: params.beneBificiaryName,
      accountnumber: params.accountNumber,
      addresss: 'At-123',
      ifsc: params.IfscCode,
      bank_id: Number(params.selectBank.value1),
      bank_name: params.selectBank.label,
      branch_address: 'At-123',
      mobileno: params.Mobile.customerMobileNumber,
      reference_benificiary_id: `${params.res.bene_id}`,
    }),
  },
);
export const addNewBeneficiarySelfIservue = createEncryptedAsyncThunk(
  'beneficiary/addNewBeneficiarySelfIservue',
  'account/CreateBenificiary',
  {
    prepareBody: (params) => ({
      remt_mobileno: params.Mobile.customerMobileNumber,
      cust_id: params.custId,
      userID: params.currentUser.userGuid,
      account_holder: params.beneBificiaryName,
      accountnumber: params.accountNumber,
      addresss: 'At-123',
      ifsc: params.IfscCode,
      bank_id: Number(params.selectBank.value1),
      bank_name: params.selectBank.label,
      branch_address: 'At-123',
      mobileno: params.Mobile.customerMobileNumber,
      reference_benificiary_id: '',
    }),
  },
);
export const ValidateUserToken = createEncryptedAsyncThunk(
  'beneficiary/ValidateUserToken',
  'Token/ValidateUserToken',
  {
    transUrl: process.env.REACT_APP_API_TOKENSERVICE,
    prepareBody: (params) => ({
      token: params.Usertoken,
      userID: params.currentUser.userGuid,
    }),
  },
);
export const getServiceNotification = createEncryptedAsyncThunk(
  'beneficiary/getServiceNotification',
  'account/GetNotificationDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      roles: params.roleId,
      serviceId: params.currentService.serviceId ? [params.currentService.serviceId] : null,
      city: params.loginData.city_ID ? [params.loginData.city_ID] : null,
      states: params.loginData.state_ID ? `${params.loginData.state_ID}` : null,
      // serviceId: params.serviceId ? params.serviceId : null,
      platform: [1],
      startDate: null,
      endDate: null,
    }),
  },
);
export const getServiceNotificationDash = createEncryptedAsyncThunk(
  'beneficiary/getServiceNotificationDash',
  'account/GetNotificationDetails',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      roles: params.roleId,
      serviceId:  null,
      // serviceId: params.currentService.serviceId ? [params.currentService.serviceId] : null,
      city: params.loginData.city_ID ? [params.loginData.city_ID] : null,
      states: params.loginData.state_ID ? `${params.loginData.state_ID}` : null,
      // serviceId: params.serviceId ? params.serviceId : null,
      startDate: null,
      endDate: null,
    }),
  },
);
export const verifyBeneficiayForAdd = createEncryptedAsyncThunk(
  'beneficiary/verifyBeneficiayForAdd',
  'Modules/VerifyBankDetails',
  {
    prepareBody: (params) => ({
      ifsc: params.IfscCode,
      accountNumber: params.accountNumber,
      id: 0,
      beneficiary_Name: params.beneBificiaryName,
      iS_Verify: 0,
      userID: params.currentUser.userGuid,
    }),
  },
);
export const BeneficiaryDelete = createEncryptedAsyncThunk(
  'beneficiary/BeneficiaryDelete',
  'account/DeleteBeneficiary',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: Number(params.currentService.serviceId),
      mobile: params.Mobile.customerMobileNumber,
      bene_id: `${params.data.benificiary_Id}`,
    }),
  },
);
export const verifyBeneficiayDetails = createEncryptedAsyncThunk(
  'beneficiary/verifyBeneficiayDetails',
  'account/PennyDropDMT',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId,
      wallettype: 1,
      bene_id: Number(params.body.benificiary_Id),
    }),
  },
);
export const verifyBeneficiayDetailsForISRU = createEncryptedAsyncThunk(
  'beneficiary/verifyBeneficiayDetailsForISRU',
  'account/ISU_VerifyBeneficiaryTransaction',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.currentService.serviceId,
      wallettype: 1,
      beneficiary_ID: params.body.benificiary_Id, //Number(params.body.benificiary_Id),
      tpin: '1234',
      username:'JANPAISA000002', // params.body.account_holder,
    }),
  },
);
export const setOTP = createEncryptedAsyncThunk(
  'beneficiary/setOTP',
  'Service/TwoWayAuthentication',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      type: 'MOBILE_VERIFICATION',
      mode: {
        mode1: 'SMS',
        mode2: '',
      },
      auth_mode: 0,
      receiverDetails: {
        mobileNo: params.Mobile.customerMobileNumber,
        emailId: '',
      },
      ipaddress: params.location.ip,
      substitutes: {
        additionalProp1: '',
        additionalProp2: '',
        additionalProp3: '',
      },
    }),
  },
);
export const verifyOtp = createEncryptedAsyncThunk('beneficiary/verifyOtp', 'Service/VerifyPreUserOTP', {
  prepareBody: (params) => ({
    otp: params.otp,
    auth_mode: 0,
    type: 'MOBILE_VERIFICATION',
    mode: {
      mode1: 'SMS',
      mode2: '',
    },
    receiverDetails: {
      mobileNo: params.Mobile.customerMobileNumber,
      emailId: '',
    },
  }),
});
export const customerAdd = createEncryptedAsyncThunk(
  'beneficiary/customerAdd',
  'account/CreateCustomer',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      customer_name: params.FullName,
      mobileno: params.Mobile.customerMobileNumber,
      remt_mobileno: params.loginData.primaryPhone,
      merchant_code: params.Mobile.customerMobileNumber,
      api_id: params.serviceId.api_id,
      isactive: 0,
    }),
  },
);
export const RegisterRemitter = createEncryptedAsyncThunk(
  'beneficiary/RegisterRemitter',
  'transaction/RegisterRemitter',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      mobile: params.Mobile.customerMobileNumber,
      firstname: params.fName,
      lastname: params.lName,
      address: params.address,
      otp: params.otp,
      pincode: params.pincode,
      stateresp: params.stateresp,
      bank3_flag: 'No',
      dob: '1991-12-20',
      gst_state: `${params.selectState.value}`,
    }),
  },
);
export const getCustomerDetails = createEncryptedAsyncThunk(
  'beneficiary/getCustomerDetails',
  'account/GetCustomerList',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      cust_mobileNo: params.customerMobileNumber.customerMobileNumber,
      api_id: params.serviceId.api_id,
    }),
  },
);
export const getQuerryRemitter = createEncryptedAsyncThunk(
  'beneficiary/getQuerryRemitter',
  'transaction/QuerryRemitter',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      event: '',
      mobile: `${params.customerMobileNumber.customerMobileNumber}`,
      bank3_flag: 'No',
    }),
  },
);
export const getBeneficiaryList = createEncryptedAsyncThunk(
  'beneficiary/getBeneficiaryList',
  'account/GetBeneficiaryDetail',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      cust_id: params.customerData.cust_id,
    }),
  },
);
export const benificiary = createSlice({
  name: 'beneficiary',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, benDeleteSuccess: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        benDeleteSuccess: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        benDeleteSuccess: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = benificiary.actions;
export default benificiary.reducer;
