import React from 'react';
// import { Link } from 'react-router-dom';
import logo from '../../assets/images/logos/logo.png';

const AuthLogo = () => {
  return (
    <div className="p-0 d-flex  justify-content-center gap-2 mt-0">
      <img src={logo} alt="profile" width='50px' height='45px'></img>
    </div>
  );
};

export default AuthLogo;
