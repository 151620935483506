import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';

const initialState = {
  dmtSuccess: false,
  creditAmountResponse: {
    error: false,
    message: '',
  },
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};

export const transactionProcess = createEncryptedAsyncThunk(
  'dmtSlice/transactionProcess',
  'transaction/CreateDMTTransaction',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.data.serviceId.serviceId,
      wallettype: 1,
      dmT_ID: 0,
      beneficiary_ID: params.data.beniDetails.benificiary_Id,
      account_No: params.data.beniDetails.accountnumber,
      bank_ID: params.data.beniDetails.bank_id,
      amount: Number(params.data.transactionAmount),
      service_Charges: Number(params.data.transactionAmuntCharges),
      total_Amount: Number(params.data.transactionAmount),
      status: 0,
      created_By: 0,
      created_Date: '2024-02-24T15:23:06.189Z',
      mobileNo: params.Mobile.customerMobileNumber,
      cust_ID: params.data.beniDetails.cust_id,
      commision_ID: 0,
      tpin: params.tPin.tPin,
      transfer_Type: params.data.selected,
    }),
  },
);
export const transactionProcessISRU = createEncryptedAsyncThunk(
  'dmtSlice/transactionProcessISRU',
  'account/ISU_CreateMoneyTransfer_Wb',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.data.serviceId.serviceId,
      wallettype: 1,
      beneficiary_ID: params.data.beniDetails.benificiary_Id,
      service_Charges: Number(params.data.transactionAmuntCharges),
      total_Amount: Number(params.data.transactionAmount),
      requestedAmount: Number(params.data.transactionAmount),
      isHoldTransaction: false,
      tpin: params.tPin.tPin,
      txn_mode: 'DMT',
      txn_type: params.data.selected,
      username: '',
    }),
  },
);
export const transactionProcessISRUPayout = createEncryptedAsyncThunk(
  'dmtSlice/transactionProcessISRUPayout',
  'account/ISU_CashTransfer_Payout',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.data.serviceId.serviceId,
      wallettype: 1,
      beneficiary_ID: params.data.beniDetails.benificiary_Id,
      tpin: params.tPin.tPin,
      transfer_Type: 'Payout',
      service_Charges: Number(params.data.transactionAmuntCharges),
      total_Amount: Number(params.data.transactionAmount),
      amount: Number(params.data.transactionAmount),
      fundTransferType: params.data.selected,
      latlong: '19.72.2687',
      custIpAddress: '103.71.16.209',
      paramA: 'string',
      paramB: 'string',
    }),
  },
);
export const getTransactionDetails = createEncryptedAsyncThunk(
  'dmtSlice/getTransactionDetails',
  'transaction/GetServiceUsageDetails',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      serviceId: params.serviceId.serviceId,
      amount: Number(params.transactionAmount),
    }),
  },
);
export const getTransactionReciept = createEncryptedAsyncThunk(
  'dmtSlice/getTransactionReciept',
  'account/GetDMTTransaction',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      dmt_transaction_userid: params.currentUser.userId,
      txn_id: params.TransactionID,
      startDate: null,
      endDate: null,
    }),
  },
);
export const getISUTransactionReciept = createEncryptedAsyncThunk(
  'dmtSlice/getISUTransactionReciept',
  'account/ISU_IMPSLite_TxnReport',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      _1: 'DMT_txn_status_api',
      _4: params.currentDate,
      _5: params.currentDate,
      _6: params.referenceid,
      _10: params.TransactionID,
    }),
  },
);
export const dmt = createSlice({
  name: 'dmt',
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return { ...state, creditAmountResponse: { error: true, message: action.payload } };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const {
  creditAmountSuccess,
  creditAmountFailed,
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
} = dmt.actions;
export default dmt.reducer;
