import React, { useEffect, useState } from 'react';
// import { toast } from 'react-toastify';
import { Collapse, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveServiceDetails } from '../../../slices/retailer/UserAppSlice/UserDetailsSlice';

const NavSubMenu = ({ icon, title, items, isUrl, suffixColor, suffix }) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = React.useState(false);
  const getActive = document.getElementsByClassName('activeLink');
  const [activeMenu, setActiveMenu] = useState([]);
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    if (isUrl) {
      setCollapsed(!collapsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveServiceDetails({ Usertoken, currentUser, activeMenu }))
      .unwrap()
      .then((res) => {
        if (res.status === 'true') {
          
          setActiveMenu(res.data[0]);
          sessionStorage.setItem('menuActive', JSON.stringify(res.data[0]));
        } else if (res.status === 'false') {
          console.log('');
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <NavItem className={collapsed && getActive ? 'activeParent' : ''} loading={loading}>
      <NavLink className="cursor-pointer gap-3" onClick={toggle}>
        <span className="sidebarIcon d-flex align-items-center">{icon}</span>
        <span className="hide-mini w-100">
          <div className="d-flex align-items-center">
            <span className="d-block">{title}</span>
            <span className="ms-auto">
              <span className={`badge me-2 ${suffixColor}`}>{suffix}</span>
              <i className={`bi fs-8 ${collapsed ? 'bi-chevron-down' : 'bi-chevron-right'}`} />
            </span>
          </div>
        </span>
      </NavLink>
      <Collapse isOpen={collapsed} navbar tag="ul" className="subMenu">
        {items.map((item) => (
          <NavItem
            key={item.title}
            className={`hide-mini ${location.pathname === item.href ? 'activeLink' : ''}`}>
            <NavLink tag={Link} to={item.href} className="gap-3">
              <span className="sidebarIcon">{item.icon}</span>
              <span className="hide-mini">
                <span>{item.title}</span>
              </span>
            </NavLink>
          </NavItem>
        ))}
      </Collapse>
    </NavItem>
  );
};
NavSubMenu.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  icon: PropTypes.node,
  isUrl: PropTypes.bool,
  suffix: PropTypes.any,
  suffixColor: PropTypes.string,
};
export default NavSubMenu;
