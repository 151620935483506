import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Navbar,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  ModalHeader,
  ModalBody,
  Modal,
  Col,
  Row,
  Table,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import Logo from "../logo/Logo";
import {
  ToggleMiniSidebar,
  ToggleMobileSidebar,
} from "../../store/customizer/CustomizerSlice";
import phonepe from "../../assets/images/icons/phonepe.png";
import gpay from "../../assets/images/icons/gpay.png";
import paytm from "../../assets/images/icons/paytm.png";
import upi from "../../assets/images/icons/upi.png";
import { logout } from "../../slices/authSlice/userSilce";
import { fetchData } from "../../slices/authSlice/dataSlice";
import user1 from "../../assets/images/users/user1.jpg";
import ProfileDD from "./ProfileDD";
import classNames from "classnames";
import _ from "lodash";

const Header = () => {
  const isDarkMode = useSelector((state) => state.customizer.isDark);
  const topbarColor = useSelector((state) => state.customizer.topbarBg);
  const userData = useSelector((state) => state.data.items);
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);

  const showMobileSidebar = useSelector(
    (state) => state.customizer.isMobileSidebar
  );

  const [proData, setProData] = useState([]);
  const [QRimage] = useState();
  const [UPIID] = useState();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const baseB4 = "data:image/jpeg;base64,";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (_.isEmpty(userData)) {
      // setLoading(true)
      dispatch(fetchData({ Usertoken, currentUser }));
    }
  }, [userData]);

  useEffect(() => {
    setProData([])
    setLoading(false)
  }, [])

  const loginData = useSelector((state) => state.data.items);
  const handleLogoutClick = () => {
    window.addEventListener("beforeunload", () => {
      dispatch(logout());
    });
    navigate("/", { replace: true });
    window.location.reload();
  };


  const toggle = () => {
    setModal(!modal);
  };
  return (
    <>
      <Navbar
        color={
          currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "8" ||
          currentUser.role == "5" ||
          currentUser.role == "6" ||
          currentUser.role == "7"
            ? topbarColor
            : topbarColor
        }
        dark={!isDarkMode}
        light={isDarkMode}
        expand="lg"
        className={`${
          currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "7" ||          
          currentUser.role == "8"
            ? "topbarAdmin admin_head_bg"
            : "topbar"
        } ${currentUser.role == "6"||
          currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "6" ||
          currentUser.role == "7"
            ? "px-md-2"
            : ""
        }`}
        loading={loading}
      >
        {/********Logo*******/}
        <div
          className={
            currentUser.role == "1" ||
            currentUser.role == "2" ||
            currentUser.role == "7" ||
            currentUser.role == "6" ||
            currentUser.role == "8"
              ? "d-lg-flex align-items-center logo-space"
              : "logo-spaceAdmin"
          }
        >
          {currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "7" ? (
            <Link to="/Dashboards/AdminDashboard">
              <Logo />
            </Link>
          ) : currentUser.role === "Distributor" ? (
            <Link to="/dashboards/distributordashboard">
              <Logo />
            </Link>
          ) : currentUser.role === "Super Distributor" ? (
            <Link to="/dashboards/superdistributordashboard">
              <Logo />
            </Link>
          ) : currentUser.role === "Agent" ? (
            <Link to="/dashboards/retailerdashboard">
              <Logo />
            </Link>
          ) : currentUser.role === "8" ? (
            <Link to="/dashboards/retailerdashboard">
              <Logo />
            </Link>
          // )  : currentUser.role === "6" ? (
          //   <Link to="/dashboards/retailerdashboard" className="d-block d-lg-none">
          //     <Logo />
          //   </Link>
          ): null}
          <Button
            close
            size="sm"
            className="ms-auto d-lg-none d-none"
            onClick={() => dispatch(ToggleMobileSidebar())}
          />
        </div>

        {currentUser.role == "1" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-lg-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Admin Dahboard!
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {currentUser.role == "2" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Business Partner Dahboard!
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {currentUser.role == "7" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    Dashboard - WELCOME, Employee Dahboard!
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {currentUser.role == "8" ? (
          <>
            <Nav
              className="text-center d-flex flex-row align-items-center"
              navbar
            >
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4 className="m-auto fw-bold">
                    {" "}
                    Dashboard - WELCOME, Super Admin Dahboard!
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {currentUser.role == "6" ? (
          <>
            <Nav className="text-center d-flex flex-row align-items-center" navbar>
              <NavItem className="d-md-block d-none">
                <Link
                  to="#"
                  className={`nav-link hov-dd ${
                    topbarColor === "white" ? "text-dark" : ""
                  }`}
                >
                  <h4
                    className="m-auto fw-bold d-none d-lg-block"
                    style={{ position: "absolute", left: "20px", top: "15px" }}
                  >
                    {" "}
                    Dashboard - WELCOME, Agent!{" "}
                  </h4>
                </Link>
              </NavItem>
            </Nav>
          </>
        ) : null}
        {/* <Nav className="me-auto d-flex flex-row align-items-center" navbar>
          {currentUser.role !== '1' ? (
            <NavItem className="d-md-block d-none ">
              <strong className="text-info">Office Whatsapp No. : 9876543210</strong>
            </NavItem>
          ) : null}
        </Nav> */}
        <div className="d-flex align-items-center">
          {/* {currentUser.role === '1' ? (
            <>
              <div className="p-0 px-2">
                <Link to="/Dashboards/AdminDashboard">
                  <Button color="info" size="md">
                    Dashboard
                  </Button>
                </Link>
              </div>
            </>
          ) : null} */}
          {/* {currentUser.role === '1' ? (
            <>
              <div className="p-0 px-2 mx-4">
                <Button color="info" size="md" onClick={() => handleRefresh()}>
                  Refresh
                </Button>
              </div>
              <div className="pt-0 px-3" onClick={() => toggle()}>
                <img src={qr} alt="qr" style={{ width: '30px', background: '#6732a7' }} />
              </div>
            </>
          ) : null} */}
          <div className="Wallet-holder">
            <div>
              {currentUser.role == "6" || currentUser.role == "1" ? (
                <span className="text-warning fw-normal">
                  AEPS Wallet : {`\u20B9`}
                  {loginData ? loginData?.aepS_Wallet_Balance : "0.00"}
                </span>
              ) : null}
            </div>
            <span className="text-warning fw-normal">
              Main Wallet : {`\u20B9`}{" "}
              {loginData ? loginData?.main_Wallet_Balance : "0.00"}
            </span>
          </div>
          <UncontrolledDropdown>
            <DropdownToggle color="transparent">
              <div className="d-flex align-items-center w-100-mo">
                <img
                  src={baseB4 + proData}
                  alt="user"
                  width="30"
                  className="rounded-circle d-none"
                />
                <img
                  src={user1}
                  alt="user"
                  width="30px"
                  height="auto"
                  className="rounded-circle mx-1"
                />
                <span className="text-info">
                  {loginData?.firstName} {loginData?.lastName}
                </span>
                <Icon.ChevronDown size={15} color="#3fa2db" />
              </div>
            </DropdownToggle>
            <DropdownMenu className="ddWidth profile-dd">
              {currentUser.status === "1" ? <ProfileDD /> : null}
              {/* <ProfileDD /> */}
              {/* <Link to="/Profile/Certificate" className="text-decoration-none ">
                <DropdownItem className="px-3 border-bottom pt-2 pb-3 d-flex">
                  Certificate
                </DropdownItem>
              </Link> */}
              <div className="p-2 px-3">
                <Button color="danger" size="sm" onClick={handleLogoutClick}>
                  Logout
                </Button>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>

          <div className="d-flex align-items-center">
          {currentUser.role == "1" ||
          currentUser.role == "2" ||
          currentUser.role == "7" ||
          currentUser.role == "8" ? (
            <>
              <Button
                color={topbarColor}
                className="d-none mx-1 border-0 hov-dd"
                onClick={() => dispatch(ToggleMiniSidebar())}
              >
                <Icon.Menu size={18} />
              </Button>
              <Button
                color={topbarColor}
                className="d-sm-block d-lg-none border-0 mx-1 hov-dd"
                onClick={() => dispatch(ToggleMobileSidebar())}
              >
                <i
                  className={classNames(
                    "bi text-dark fs-3",
                    showMobileSidebar ? "bi-x" : "bi-list"
                  )}
                />
              </Button>
            </>
          ) :
          currentUser.role == '6' ? (
            <Button
              color={topbarColor}
              className="d-sm-block d-lg-none border-0 mx-4 hov-dd"
              onClick={() => dispatch(ToggleMobileSidebar())}
            >
              <i
                  className={classNames(
                    "bi text-dark fs-3",
                    showMobileSidebar ? "bi-x" : "bi-list"
                  )} />
            </Button>
          ) : (
            <Button
              color={topbarColor}
              className="d-sm-block d-lg-none border-0 mx-4 hov-dd"
              onClick={() => dispatch(ToggleMobileSidebar())}
            >
              <i
                  className={classNames(
                    "bi text-dark fs-3",
                    showMobileSidebar ? "bi-x" : "bi-list"
                  )} />
            </Button>
          )}
        </div>
        </div>
      </Navbar>
      {modal ? (
        <Col xs="8" md="12">
          <Modal isOpen={toggle} fade={false} toggle={toggle.bind(null)}>
            <ModalHeader
              toggle={toggle.bind(null)}
              style={{ padding: "10px", color: "#000" }}
            >
              UPI Collection
            </ModalHeader>
            <ModalBody>
              <Row className="row">
                <Col md="4">
                  <img
                    src={baseB4 + QRimage}
                    alt="123"
                    style={{ width: "150px", border: "1px solid #dfe2e6" }}
                  />
                </Col>
                <Col md="8" className="mt-0">
                  <Table className="table-bordered">
                    <thead>
                      <tr>
                        <td className="text-nowrap text-black">Name </td>
                        <td>
                          {loginData.firstName}
                          {loginData.lastName}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-nowrap text-black">UPI ID </td>

                        <td>{UPIID}</td>
                      </tr>
                    </thead>
                  </Table>
                  <Row>
                    <img
                      src={phonepe}
                      alt="phonepe"
                      style={{ width: "60px" }}
                    />
                    <img src={gpay} alt="gpay" style={{ width: "60px" }} />
                    <img src={upi} alt="upi" style={{ width: "60px" }} />
                    <img src={paytm} alt="paytm" style={{ width: "60px" }} />
                  </Row>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Col>
      ) : null}
    </>
  );
};

export default Header;
