import { createSlice } from "@reduxjs/toolkit";
import { createEncryptedAsyncThunk } from "../Utility/APIUtils";

const initialState = {
  // creditAmount
  creditAmountSucess: false,

  creditAmountResponse: {
    error: false,
    message: "",
  },
  commonDeleteResponse: {
    status: false,
    message: "",
  },
  commonDeleteSucces: false,
  error: "",
  loading: false,
};

export const createRetailerNew = createEncryptedAsyncThunk(
  "manageRoleDistributor/createRetailer",
  "account/CreateUser",
  {
    prepareBody: (params) => {
      const todayDate = new Date().toISOString();
      return {
        userID: params.currentUser.userGuid,
        nameTitle: params.title,
        username: "",
        password: "",
        tpin: "",
        emailAddress: params.emaiID,
        firstName: params.firstName,
        middleName: params.middleName,
        lastName: params.lastName,
        primaryPhone: params.contactNumber,
        primaryPhoneType: 0,
        secondaryPhone: params.altContactNumber,
        secondaryPhoneType: 0,
        role: params.roleId,
        senior_ID: params.currentUser.userId,
        business_Name: params.businessName,
        business_Add: params.businessAddress,
        business_Contact_No: params.businessContact,
        business_Type: "string",
        state_ID: params.state_ID,
        city_ID: params.city_ID,
        pincode: params.pincode,
        area: "string",
        landMark: params.landmark,
        address: params.address,
        locationtype: params.locationtype || 0,
        created_By: 0,
        plan_id: 0,
        whatsapp_no: params.whatupNo,
        block_add: params.block,
        gram_panchayat: params.grampanchayat,
        post_office: params.postoffice,
        police_thana: params.policethana,
        gram: params.gram,
        other: "",
        gender: params.gender || 0,
        dob: params.dob || todayDate,
        custom_Attributes: {
          ip: params.location.ip,
          deviceId: "",
          deviceType: "",
          lattitude: params.location.lattitude,
          longitude: params.location.longitude,
        },
      };
    },
  }
);

export const createRetailer = createEncryptedAsyncThunk(
  "manageRoleDistributor/createRetailer",
  "account/CreateUser",
  {
    prepareBody: (params) => {
      const todayDate = new Date().toISOString();
      return {
        userID: params.currentUser.userGuid,
        nameTitle: params.title,
        username: "",
        password: "",
        tpin: "",
        emailAddress: params.body.emaiID,
        firstName: params.body.firstName,
        middleName: params.body.middleName,
        lastName: params.body.lastName,
        primaryPhone: params.body.contactNumber,
        primaryPhoneType: 0,
        secondaryPhone: params.body.altContactNumber,
        secondaryPhoneType: 0,
        role: params.roleId,
        senior_ID: params.currentUser.userId,
        business_Name: params.body.businessName,
        business_Add: params.businessAddress,
        business_Contact_No: params.body.businessContact,
        business_Type: "string",
        state_ID: params.selectState.value,
        city_ID: params.selectCity.value,
        pincode: params.body.pincode,
        area: "string",
        landMark: params.body.landmark,
        address: params.address,
        locationtype: params.locationtype || 0,
        created_By: 0,
        plan_id: 0,
        whatsapp_no: params.body.whatupNo,
        block_add: params.body.block,
        gram_panchayat: params.body.grampanchayat,
        post_office: params.body.postoffice,
        police_thana: params.body.policethana,
        gram: params.body.gram,
        other: "",
        gender: params.gender || 0,
        dob: params.dob || todayDate,
        custom_Attributes: {
          ip: params.location.ip,
          deviceId: "",
          deviceType: "",
          lattitude: params.location.lattitude,
          longitude: params.location.longitude,
        },
      };
    },
  }
);
// export const getAllRetailer = createEncryptedAsyncThunk(
//   'manageRoleDistributor/getAllRetailer',
//   (params) =>
//     `account/GetUserDetailsByRole?userID=${params.currentUser.userId}&role=${params.roleId}`,
//   {
//     method: 'GET',
//   },
// );
export const getAllRetailer = createEncryptedAsyncThunk(
  "manageRoleDistributor/getAllRetailer",
  "account/GetUserDetailsByRole",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      role: params.roleId,
      documentRequired: 0,
      status: 3,
    }),
  }
);
export const manageCreditDebitToUserDistributor = createEncryptedAsyncThunk(
  "manageRoleDistributor/manageCreditDebitToUserDistributor",
  "Wallet/AddWalletTopup",
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      transaction_id: "",
      from_user_id: `${params.currentUser.userId}`,
      to_user_id: params.selectRetailer.value,
      trans_type: params.transType.value,
      topup_mode: 1,
      amount: Number(params.amount),
      narration: "",
      created_by: params.currentUser.userId,
    }),
  }
);

export const GetIDAllotmentDistributor = createEncryptedAsyncThunk(
  "manageRoleDistributor/GetIDAllotmentDistributor",
  "account/GetIDAllotment",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      requestUserID: params.userId || 0,
      req_startDate: params.startDate,
      req_endDate: params.endDate,
    }),
  }
);

export const UpdateIDAllotmentDistributor = createEncryptedAsyncThunk(
  "manageRoleDistributor/UpdateIDAllotmentDistributor",
  "account/UpdateIDAllotment",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      requestUserID: Number(params.reqFrom),
      allotmentID: params.id,
      status: params.status,
      key: "IDAllotmentCount",
      value: `${params.reqCount}`,
    }),
  }
);

export const GetIdAllotmentPlan = createEncryptedAsyncThunk(
  "manageRoleDistributor/getIdAllotmentPlan",
  "account/GetIDAllotmentPlan",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      roleID: Number(params.roleId),
      status: params.status,
    }),
  }
);

export const GetIdAllotmentPlanAdmin = createEncryptedAsyncThunk(
  "manageRoleDistributor/GetIdAllotmentPlanAdmin",
  "account/GetIDAllotmentPlan",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      roleID: params.roleId,
      status: params.status,
    }),
  }
);

export const UpdateIdAllotmentPlan = createEncryptedAsyncThunk(
  "manageRoleDistributor/UpdateIdAllotmentPlan",
  "account/UpdateIDAllotmentPlan",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      // plan_ID: 0,
      // plan_Name: "",
      // plan_Description: "",
      // amount: 0,
      // request_id_count: 0,
      // status: 0,
    }),
  }
);

export const CreateIDAllotmentDistributor = createEncryptedAsyncThunk(
  "manageRoleDistributor/CreateIDAllotmentDistributor",
  "account/CreateIDAllotment",
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      quantity: params.quantity || 0,
      amount: params.amount,
      toUserId: params.senior_ID || 1,
      planID: params.planID,
    }),
  }
);

export const manageRoleDistributor = createSlice({
  name: "manageRoleDistributor",
  initialState,
  reducers: {
    creditAmountSuccess(state) {
      return { ...state, creditAmountSucess: true };
    },
    creditAmountFailed(state, action) {
      return {
        ...state,
        creditAmountResponse: { error: true, message: action.payload },
      };
    },
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return {
        ...state,
        rechargeResponse: { status: true, message: action.payload },
      };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: "" },
      };
    },
  },
});
export const {
  FetchSuccess,
  FetchFailed,
  DeleteSuccess,
  DeleteFailed,
  DeleteInitial,
  creditAmountSuccess,
  creditAmountFailed,
} = manageRoleDistributor.actions;
export default manageRoleDistributor.reducer;
